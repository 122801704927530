import { render, staticRenderFns } from "./TruidTab.vue?vue&type=template&id=158d3ede&scoped=true&"
import script from "./TruidTab.vue?vue&type=script&lang=js&"
export * from "./TruidTab.vue?vue&type=script&lang=js&"
import style0 from "./TruidTab.vue?vue&type=style&index=0&id=158d3ede&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158d3ede",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VImg,VOverlay,VProgressCircular,VRow})
